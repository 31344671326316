<template>
  <div>

    <HeaderMain />

    <!-- ЦЕНТР ПОДДЕРЖКИ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #F3F3F3;
               position: relative;
               overflow: hidden;
               max-height: 500px;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Центр<br>поддержки<br>клиентов</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Полная поддержка<br>24/7 и семь дней<br>в неделю</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/support.png" alt="support"
                 style="position: absolute;
                 bottom: 0;
                 height: 100%;
                 right: 0;"
            >
          </div>


          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 25px;
               padding-bottom: 35px;
            ">

            <div class="w-layout-vflex item _1 w-100">
              <div class="w-100">
                <div class="w-layout-vflex flex-good"
                     style="flex-flow: row; align-items: center; margin-bottom: 20px;">
                  <img class="img-20" src="../../../../../public/images/star.svg" alt="pdf">
                  <div class="text-mini semibold">Популярные вопросы</div>
                </div>

                <AccordionCard
                    v-for="(question, index) in questions"
                    :key="index"
                    :title="question.title"
                    :content="question.content"
                />

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- КНОПКИ ПЕРЕКЛЮЧЕНИЯ ТИПА ВОПРОСОВ -->
    <div class="section color">
      <div
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
          class="container one w-container pt-0 flex flex-direction-row direction-adaptive"
          style="border-bottom: none;">

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(0)"
                :class="[type === 0 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Получение займа
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(1)"
                :class="[type === 1 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Возврат займа
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(2)"
                :class="[type === 2 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Разное
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                id="openLabel"
                @click="changeType(3)"
                :class="[type == 3 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Первая помощь
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- ВОПРОСЫ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               position: relative;
               overflow: hidden;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <div class="text-mini semibold">Популярные вопросы</div>
                <h1 v-if="type === 0" class="heading">Получение займа</h1>
                <h1 v-else-if="type === 1" class="heading">Возврат займа</h1>
                <h1 v-else-if="type === 2" class="heading">Разное</h1>
                <h1 v-else-if="type === 3" class="heading">Первая помощь</h1>
              </div>
            </div>
          </div>


          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 25px;
               padding-bottom: 35px;
            ">

            <div class="w-layout-vflex item _1 w-100">
              <div class="w-100">

                <AccordionCard
                    v-show="type === 0"
                    v-for="question in questions_get_loan"
                    :key="'A' + question.id"
                    :title="question.title"
                    :content="question.content"
                />

                <AccordionCard
                    v-show="type === 1"
                    v-for="question in questions_return_loan"
                    :key="'B' + question.id"
                    :title="question.title"
                    :content="question.content"
                />

                <AccordionCard
                    v-show="type === 2"
                    v-for="question in questions_miscellaneous"
                    :key="'C' + question.id"
                    :title="question.title"
                    :content="question.content"
                />

                <AccordionCard
                    v-show="type === 3"
                    v-for="question in questions_first_aid"
                    :key="'D' + question.id"
                    :title="question.title"
                    :content="question.content"
                />

                <a
                    v-show="type === 3"
                    style="margin-top: 30px;"
                    @click="goToScammers"
                    class="button-bust w-button blue-button">
                  О мошенниках
                </a>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- КОЛОБОК В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Колобок в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain, AccordionCard } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
      type: 0,

      questions: [
        {
          id: 0,
          title: 'Какие условия первого займа?',
          content: 'Для первого займа действует акция: вы можете оформить заём на срок от 91 дня с процентной ставкой от 0%. В случае своевременного погашения займа, по нему не будут начислены проценты, если применяется акция для новых клиентов.',
        },
        {
          id: 1,
          title: 'Как подать заявку?',
          content: 'Для подачи заявки необходимо заполнить анкету на сайте, указав свои контактные данные, сумму займа и срок. После этого заявка будет отправлена на рассмотрение, и вы получите ответ в течение нескольких минут.',
        },
        {
          id: 2,
          title: 'Как продлить срок займа?',
          content: 'Если вам нужно продлить срок займа, вы можете воспользоваться опцией пролонгации. Для этого необходимо погасить проценты за текущий период и подать заявку на продление через личный кабинет или обратившись в службу поддержки.',
        },
        {
          id: 3,
          title: 'На какой срок можно продлить заём?',
          content: 'Заём можно продлить на срок, соответствующий условиям вашего текущего договора. Обычно пролонгация возможна на срок от 91 дня или более, в зависимости от суммы и типа займа.',
        },
        {
          id: 4,
          title: 'Как снова взять заём?',
          content: 'Чтобы снова взять заём, необходимо полностью погасить текущий. После этого вы сможете оформить новый заём на аналогичных условиях через ваш личный кабинет на сайте.',
        },
      ],

      // ВОПРОСЫ: получение займа
      questions_get_loan: [
        {
          id: 5,
          title: 'Какие условия первого займа?',
          content: 'Для первого займа сроком от 91 дня действует акция с процентной ставкой от 0%. В случае своевременного погашения займа проценты не будут начислены, если используется акция для новых клиентов.',
        },
        {
          id: 6,
          title: 'Как подать заявку?',
          content: 'Для подачи заявки нужно заполнить анкету на сайте, указав свои данные, сумму и срок займа. После этого заявка отправляется на рассмотрение, и ответ приходит в течение нескольких минут.',
        },
        {
          id: 7,
          title: 'Как снова взять заём?',
          content: 'Для того чтобы снова взять заём, необходимо погасить текущий заём полностью. После этого можно подать заявку на новый заём через личный кабинет на сайте или по телефону.',
        },
        {
          id: 8,
          title: 'Как быстро рассматривается заявка?',
          content: 'Заявки рассматриваются в течение нескольких минут. В редких случаях процесс может занять до 24 часов, если требуется дополнительная проверка данных.',
        },
        {
          id: 9,
          title: 'Как быстро придут деньги?',
          content: 'После одобрения заявки деньги поступят на ваш банковский счёт или карту в течение нескольких минут. В зависимости от выбранного способа, перевод может занять до 24 часов.',
        },
        {
          id: 10,
          title: 'Какими способами можно получить заём?',
          content: 'Вы можете получить заём на банковскую карту, банковский счёт или наличными через системы денежных переводов. Выберите удобный способ при оформлении заявки.',
        },
        {
          id: 11,
          title: 'У меня нет банковской карты. Как получить заём?',
          content: 'Если у вас нет банковской карты, вы можете получить заём на банковский счёт или наличными через системы денежных переводов.',
        },
        {
          id: 12,
          title: 'Как отказаться от полученного займа?',
          content: 'Вы можете отказаться от полученного займа в течение 14 дней с момента его получения, погасив тело займа без начисленных процентов. Для этого обратитесь в службу поддержки.',
        },
        {
          id: 13,
          title: 'Не пришло SMS с кодом. Что делать?',
          content: 'Если вы не получили SMS с кодом подтверждения, проверьте правильность введённого номера телефона. Если всё верно, повторите запрос кода. В случае повторной ошибки обратитесь в поддержку.',
        },
        {
          id: 14,
          title: 'Как узнать решение по моей заявке?',
          content: 'Вы получите уведомление о решении по заявке на указанный номер телефона или электронную почту. Также вы можете проверить статус в личном кабинете на сайте.',
        },
        {
          id: 15,
          title: 'По какой причине могут отказать в займе?',
          content: 'Отказ в займе может быть связан с недостаточной кредитной историей, ошибками в анкете или несоответствием требованиям. В случае отказа вы можете обратиться в поддержку для уточнения причин.',
        },
        {
          id: 16,
          title: 'Что такое «предодобренный заём»?',
          content: '«Предодобренный заём» — это предложение, которое вы можете получить на основании вашей кредитной истории и текущего займа. Он оформляется быстрее, поскольку основные проверки уже пройдены.',
        },
        {
          id: 17,
          title: 'Почему моя заявка одобрена, но в займе отказали?',
          content: 'Это может быть связано с изменениями в вашей кредитной истории или с выявлением несоответствий в данных после первичного одобрения. В случае отказа вы можете уточнить причину в службе поддержки.',
        },
      ],

      // ВОПРОСЫ: возврат займа
      questions_return_loan: [
        {
          id: 18,
          title: 'Как погасить заём?',
          content: 'Вы можете погасить заём через личный кабинет на сайте, выбрав удобный способ оплаты: банковской картой, банковским переводом или через систему денежных переводов.',
        },
        {
          id: 19,
          title: 'Можно ли погасить заём досрочно?',
          content: 'Да, вы можете погасить заём досрочно в любое время. В этом случае проценты начисляются только за фактический период использования займа.',
        },
        {
          id: 20,
          title: 'Что делать, если не могу погасить заём вовремя?',
          content: 'Если вы не успеваете погасить заём в срок, вы можете продлить его, оформив продление через личный кабинет. Важно сделать это до наступления даты погашения.',
        },
        {
          id: 21,
          title: 'Как продлить срок займа?',
          content: 'Для продления срока займа вам нужно войти в личный кабинет, выбрать активный заём и оформить продление, оплатив только проценты за использованный период.',
        },
        {
          id: 22,
          title: 'Какие условия продления займа?',
          content: 'Вы можете продлить заём на срок, который выберете при продлении. Для этого вам нужно погасить проценты за использованный период. Основная сумма останется к погашению позже.',
        },
        {
          id: 23,
          title: 'Какие последствия при просрочке займа?',
          content: 'В случае просрочки займа могут начисляться штрафные проценты и пени. Мы рекомендуем как можно скорее связаться с нами для решения ситуации.',
        }
      ],

      // ВОПРОСЫ: разное
      questions_miscellaneous: [
        {
          id: 24,
          title: 'Какие технологии используются для обеспечения безопасности?',
          content: 'Мы используем современные технологии для защиты ваших данных, включая шифрование и многоуровневую аутентификацию. Вся информация защищена согласно законодательству РФ.',
        },
        {
          id: 25,
          title: 'Каковы преимущества сотрудничества с Колобок?',
          content: 'Мы предлагаем прозрачные и выгодные условия: первый заём на 7 дней бесплатно, участие в программе лояльности и возможность продления займа, если не успеваете его погасить.',
        },
        {
          id: 26,
          title: 'Что делать, если не пришло подтверждение по заявке?',
          content: 'Если вы не получили подтверждение по заявке, проверьте указанный контактный номер и почту. Если проблема сохраняется, обратитесь в службу поддержки.',
        },
        {
          id: 27,
          title: 'Работаете ли вы круглосуточно?',
          content: 'Да, наша служба поддержки работает круглосуточно, 24/7. Вы можете обратиться за помощью в любое время, позвонив по бесплатному номеру 8-800-707-02-47.',
        },
        {
          id: 28,
          title: 'Могу ли я доверять вашим условиям?',
          content: 'Да, мы предоставляем прозрачные условия и гарантируем безопасность ваших данных. Наши условия соответствуют законодательству, а компания зарегистрирована в реестре микрофинансовых организаций.',
        },
        {
          id: 29,
          title: 'Как я могу обратиться с жалобой?',
          content: 'Вы можете обратиться с жалобой в Службу финансового уполномоченного. Контакты и подробности доступны на нашем сайте в разделе "Контакты".',
        },
      ],

      // ВОПРОСЫ: первая помощь
      questions_first_aid: [
        {
          id: 30,
          title: 'Что делать, если я не могу погасить заём вовремя?',
          content: 'Если у вас возникли финансовые трудности, обратитесь к нам для оформления продления займа или реструктуризации задолженности. Мы постараемся предложить вам подходящее решение.',
        },
        {
          id: 31,
          title: 'Как получить помощь при оформлении заявки?',
          content: 'Если у вас возникли сложности при подаче заявки, вы можете обратиться в нашу службу поддержки по телефону или через онлайн-чат. Мы поможем вам заполнить анкету и решить технические проблемы.',
        },
        {
          id: 32,
          title: 'Что делать, если я потерял доступ к личному кабинету?',
          content: 'Если вы потеряли доступ к личному кабинету, воспользуйтесь функцией восстановления пароля на сайте. В случае проблем обратитесь в службу поддержки для восстановления доступа.',
        },
        {
          id: 33,
          title: 'Как обезопасить себя от мошенников?',
          content: 'Никогда не передавайте свои личные данные и данные банковской карты третьим лицам. Мы никогда не запрашиваем ваши пароли или PIN-коды. Все операции осуществляются через защищённые каналы связи.',
        },
        {
          id: 34,
          title: 'Как связаться с вашей службой поддержки?',
          content: 'Вы можете связаться с нами по телефону 8-800-707-02-47 или по форме на сайте.',
        },
      ]
    };
  },
  components: {
    Footer,
    HeaderMain,
    AccordionCard
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToScammers() {
      this.$router.push('/scammers');
    },
    changeType(type) {
      this.type = type;
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
